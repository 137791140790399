






















































































































































import ViewInfiniteCollection from "./infiniteCollection";
export default ViewInfiniteCollection;
