import Axios from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';
import Snackbars from '@/components/Snackbars.vue';
import { Watch } from 'vue-property-decorator';

@Component({
  components: {
    Snackbars
  }
})
export default class ViewInfiniteCollection extends Vue {
  searchData = '';
  benched = 0;
  scrollListHeight = 300;
  screenHeight = 0;
  drawer = true;
  selectedItemName = '';
  isLoading = false;
  dataLoading = false;
  valid = true;
  form = {
    id: -1,
    name: "",
    describe: ""
  }
  allInfiniteLibData = [];
  filterList: any = [];
  operationHistoryRecord = [];
  historyRecordDialog = false;

  nameRules = [
    (v: any) => !!v || 'Name is required',
    (v: string | any[]) => (v && v.length <= 100) || 'Name must be less than 100 characters',
  ]

  describeRules = [
    (v: any) => !!v || 'Describe is required',
    (v: string | any[]) => (v && v.length <= 2000) || 'describe must be less than 2000 characters',
  ]

  mounted() {
    const _this = this;
    window.onresize = function () { // 定义窗口大小变更通知事件
      _this.screenHeight = document.documentElement.clientHeight; //窗口高度
    };
    _this.screenHeight = document.documentElement.clientHeight;
    this.getAllNameData();
  }

  filterUrlData() {
    const _this = this;
    _this.filterList = [];
    _this.allInfiniteLibData.forEach(function (value: any) {
      if (new RegExp(_this.searchData, 'i').test(value.name)) {
        _this.filterList.push(value);
      }
    });
    return this.filterList;
  }

  @Watch("screenHeight")
  windowHeightAuto(val: number) {
    this.scrollListHeight = val - 210;
  }

  @Watch("selectedItemName")
  selectItem(val: string) {
    const _this = this;
    _this.$axios({
      url: '/api/v1/infinite/lib/name',
      method: 'post',
      data: {
        name: val
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        const result = response.data.data;
        console.log(result);
        _this.form.id = response.data.data.id;
        _this.form.name = response.data.data.name;
        _this.form.describe = response.data.data.describe;
        _this.$toast.success(response.data.message);
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  getOperationHistoryRecord() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/infinite/lib/record',
      method: 'post',
      data: {
        name: _this.form.name
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        const result = response.data.data;
        _this.operationHistoryRecord = result;
        _this.$toast.success(response.data.message);
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });


  }

  getAllNameData() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/infinite/lib',
      method: 'get'
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.allInfiniteLibData = response.data.data;
        _this.filterList = _this.allInfiniteLibData;
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  deleteData() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/infinite/lib',
      method: 'delete',
      data: this.form
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.getAllNameData();
        (_this.$refs as any).form.reset()
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  commitForm() {
    const _this = this;
    (_this.$refs as any).form.validate();
    _this.$axios({
      url: '/api/v1/infinite/lib',
      method: 'post',
      data: this.form
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.getAllNameData();

      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  linkClick(item: any) {
    this.form = {
      id: item.id,
      name: item.name,
      describe: item.describe
    }
  }


  newForm() {
    this.form = {
      id: -1,
      name: '',
      describe: ''
    }
  }


}


