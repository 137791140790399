











import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Snackbars extends Vue {
  private name: string = "Snackbars";
  @Prop() private text!: string;
  @Prop() private value!: any;
  @Prop() private color!: string;
  @Prop() private timeout!: string;
  @Prop() private vertical: string = "vertical";
}
